.home-newsletter {
  position: relative;
  padding-top: 104px;
  padding-bottom: 88px;
  background: linear-gradient(to top, #EAEADC, #F4F4EA);

  @media only screen and (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  .tied-up {
    position: absolute;
    top: -20%;
    right: 0;
    left: 0;
    margin: auto;
  }

  h1,
  h4 {
    font-family: Caladea;
    font-style: italic;
  }

  h1 {
    font-size: 48px;
    color: #3A3A3A;
    margin-bottom: 14px;
    font-weight: bold;
  }

  h4 {
    font-size: 32px;
    color: #719B98;
    font-weight: 300;
  }

  h1,
  h4,
  .input-group {
    max-width: 498px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  .input-group {
    margin-top: 39px;

  }

  .input-group input:not([type=submit]) {
    background-color: #fff;
    color: #c9c9c9;
    border: none;
    outline: 0;
    font-family: Mulish;
    font-size: 12px;
    font-style: italic;
    height: 34px;
    border-radius: 4px;
    max-width: 353px;
    width: 100%;
  }

  .input-group input::placeholder {
    color: #c9c9c9;;
  }

  .input-group input[type=submit] {
    font-size: 16px;
    font-family: Mulish;
    font-weight: bold;
    padding: 7px 15px;
    line-height: 1px;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    margin-left: 16px !important;
    background-color: #719B98;
    color: #fff;
    border-color: #719B98;
    max-width: 128px;
    width: 100%;
  }
}
