.home-introduction {
  padding-top: 139px;
  margin-right: auto;
  margin-left: auto;
  position: relative;

  h1 {
    font-weight: bold;
    font-style: italic;
    max-width: 502px;
    text-align: center;
  }

  p {
    margin-top: 45px;
    font-weight: 400;
    color: #719B98;
    font-size: 24px;
    text-align: center;
    display: block;
    max-width: 664px;
  }

  h1,
  p {
    font-family: Caladea;
    font-style: italic;
    margin-right: auto;
    margin-left: auto;
  }

  .home-introduction-bg {
    max-width: 1098px;
    width: 100%;
    height: 918px;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    z-index: 2;


    @media only screen and (max-width: 1110px) {
      padding-left: 25px;
      padding-right: 25px;
      height: auto;
    }
  }

  .home-introduction-banner {
    width: 100%;
    margin: 120px auto 0;
    display: block;
    position: relative;
    z-index: 5;

    @media only screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
