@font-face {
    font-family: Mulish;
    src: url("../assets/font/Mulish-Variable.ttf") format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Caladea';
    src: url("../assets/font/Caladea-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: 'Caladea';
    src: url("../assets/font/Caladea-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Caladea';
    src: url("../assets/font/Caladea-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: 'Caladea';
    src: url("../assets/font/Caladea-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Mulish;
    src: url("../assets/font/Mulish-Variable-Italic.ttf") format('truetype-variation');
    font-style: italic;
}
