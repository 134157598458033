.home-functionalities {
  background: linear-gradient(to top, #EAEADC, #F4F4EA);

  .inner-home-functionalities {
    max-width: 1081px;
    width: 100%;
    margin: 0 auto;
    padding-top: 139px;
    padding-bottom: 226px;

    @media only screen and (max-width: 1090px) {
      padding-left: 25px;
      padding-right: 25px;
    }

    @media only screen and (max-width: 767px) {
      padding-top: 50px;
    }

    h2 {
      font-family: Caladea;
      font-weight: bold;
      font-size: 36px;
      font-style: italic;
      max-width: 849px;
      text-align: center;
      margin: 0 auto 86px;
    }

    .row {
      row-gap: 104px;
    }
  }
}
