.functional-item {
  svg {
    height: 100px;
    width: auto;
    margin: 0 auto 49px;
    display: block;
  }

  h5,
  p {
    font-family: Mulish;
    text-align: center;
  }

  h5 {
    font-size: 18px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
  }
}
