@import '~bootstrap/dist/css/bootstrap.min.css';
@import "./pages/font.css";

.body {
    background-color: #F4F4EA;
    padding-top: 34px;
    min-height: 100vh;
}

.body button,
.body input {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
