.icon-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 4px;
  padding: 15px;
  column-gap: 11px;
  text-decoration: none;
  font-weight: bold;
  font-family: Mulish;
  line-height: 1;


  svg {
    width: 24px;
    height: auto;
  }

  span {
    font-size: 21px;
  }
}
