.main-header {
  margin: 0 auto 0;
  max-width: 1349px;
  width: 100%;

  @media only screen and (max-width: 1350px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  nav ul {
    list-style: none;
    padding-left: 0;
    column-gap: 40px;
    align-content: center;
    margin-bottom: 0;
    display: none !important;

    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }

  nav ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  li a {
    color: #719B98;
    text-decoration: none;
    font-family: Mulish;
    font-weight: bold;
    font-size: 21px;
    line-height: 1;
  }
}
