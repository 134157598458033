.shared-footer {

  .inner-shared-footer {
    max-width: 1081px;
    width: 100%;
    margin: 0 auto;
    padding-top: 61px;
    padding-bottom: 61px;


    @media only screen and (max-width: 1090px) {
      padding-left: 25px;
      padding-right: 25px;
      flex-direction: column;
      row-gap: 50px;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-left: 0;
      column-gap: 14px;
      margin-bottom: 0;

    }

    nav ul {
      column-gap: 30px;
      @media only screen and (max-width: 1090px) {
        flex-direction: column;
        align-items: center;
        row-gap: 25px;
      }
    }

    ul li {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    li a {
      color: #719B98;
      text-decoration: none;
      font-family: Mulish;
      font-weight: bold;
      font-size: 21px;
      line-height: 1;
    }

    img,
    svg {
      border-radius: 4px;
    }
  }

  .shared-footer-strip {
    padding-top: 16px;
    padding-bottom: 27px;
    background-color: #3A3A3A;

    p {
      text-align: center;
      font-family: Mulish;
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      color: #F4F4EA;
      margin-bottom: 0;
    }
  }
}
