.home-about {
  background: linear-gradient(to top, #EAEADC, #F4F4EA);
  padding-top: 133px;
  padding-bottom: 128px;

  .inner-home-about {
    max-width: 1081px;
    width: 100%;
    margin: 0 auto;


    @media only screen and (max-width: 1090px) {
      padding-left: 25px;
      padding-right: 25px;
    }

    h1 {
      font-family: Caladea;
      font-weight: bold;
      font-style: italic;
      color: #719B98;
      font-size: 42px;
      margin-bottom: 36px;
    }

    p {
      font-family: Mulish;
      font-size: 18px;
      font-weight: 300;
      color: #3A3A3A;
    }

    h1,
    p {
      max-width: 491px;
      width: 100%;
    }
  }

  img {
    max-width: 504px;
    width: 100%;
    height: auto;
    display: block;
  }

  .picture-frame img + p {
    text-align: center;
    margin-top: 34px;
    font-family: Mulish;
    font-size: 14px;
    font-weight: bold;
    color: #719B98;
  }

  .about-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 1081px) {
      flex-direction: column;
      align-items: center;
    }

    .text-frame {
      width: 100%;

      @media only screen and (max-width: 1081px) {
        width: auto;
      }
    }

    .picture-frame {
      max-width: 504px;
      width: 100%;
    }
  }
}
